import en from './locales/en.json';
import fr from './locales/fr.json';

export default defineI18nConfig(() => ({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    en: en,
    fr: fr,
  },
  locales: ['en', 'fr'],
  defaultLocale: 'fr',
}));
